.hero-item-loader-container p,
.hero-item-loader-container button,
.question-and-answer-loader-container p,
.question-and-answer-loader-container div,
.section-thumbnail-loader-container div,
.section-thumbnail-loader-container p,
.product-details-section-loader div,
.product-details-section-loader p,
.mywudify-loader div,
.product-item-mini-loader div {
  animation: hintloading 2s ease-in-out 0s infinite reverse;
  -webkit-animation: hintloading 2s ease-in-out 0s infinite reverse;
}

@keyframes hintloading {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
@-webkit-keyframes hintloading {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
