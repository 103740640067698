.hero-hidden {
  width: 0;
  height: 0;
  /* opacity: 0; */
  transition: width 4s, height 4s;
}

.hero-visible {
  width: 100vh;
  height: 100vh;
  /* opacity: 1; */
  transition: width 4s, height 4s;
}
/* .hero-hidden {
  display: none;
  opacity: 0;
  transition: display 0s 2s, opacity 2s linear;
}

.hero-visible {
  display: block;
  opacity: 1;
  transition: opacity 2s linear;
} */
